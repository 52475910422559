<script setup lang="ts">
import { defineProps, defineEmits, computed, onBeforeUnmount } from "vue";
import { GroupByFolders } from "o365-data-tree";


const emits = defineEmits<{
    (event: "changeFolderGroup", e: Event): Promise<void>;
}>();

const props = defineProps<{
    groupsByFolders: GroupByFolders;
    folderLevel: number
}>();    

const onChange = async (event: Event) => {    
    emits("changeFolderGroup", event)
}

const isSelectDisabled = computed((): boolean => props.folderLevel > 0);

</script>

<template>
    <div class = "toolbar">
        <select
            :disabled = "isSelectDisabled"             
            @change="(e: Event) => onChange(e)" 
            class = "form-select"
        >
            <option v-for="(folder, index) in groupsByFolders.folders" :value="index">  {{ folder.name }} </option>
        </select>
    </div>

</template>

<style scoped>
.toolbar {
    padding: 0 1rem;
    margin-top: 1rem;
}
</style>